import { theme as baseTheme } from "@chakra-ui/react";

const colors = {
  ...baseTheme.colors,
  // brand: {
  //   "100": "#dc4441",
  // },
  // red: {
  //   base:  "#dc4441"
  // }
  primary: "#dc4441",
  secondary: "green",
};

export default colors;
