import { extendTheme } from "@chakra-ui/react";

// Global style overrides
import styles from "./styles";

// Foundational style overrides
import colors from "./colors";
import fonts from "./fonts";
import table from "./table";
// import borders from './foundations/borders'

// Component style overrides
// import Button from './components/button'

const overrides = {
  colors,
  fonts,
  styles,

  // borders,
  // Other foundational style overrides go here
  components: {
    table,
    // Button,
    // Other components go here
  },
};

export default extendTheme(overrides);
