const styles = {
  global: {
    "html, body": {
      backgroundColor: "white",
      lineHeight: "tall",
    },
    a: {
      color: "primary",
    },
  },
};

export default styles;
